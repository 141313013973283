export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.region',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'workingScheduleName',
    label: 'field.workingSchedule',
    localization: true,
  },
  {
    key: 'attendanceDate',
    label: 'field.attendanceDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'firstWorkingShift',
    label: 'field.firstWorkingShift',
    hideToggle: true,
  },
  {
    key: 'firstCheckinLate',
    label: 'field.firstCheckinLate',
    hideToggle: true,
  },
  {
    key: 'firstCheckoutEarly',
    label: 'field.firstCheckoutEarly',
    hideToggle: true,
  },
  {
    key: 'lastWorkingShift',
    label: 'field.lastWorkingShift',
    hideToggle: true,
  },
  {
    key: 'lastCheckinLate',
    label: 'field.lastCheckinLate',
    hideToggle: true,
  },
  {
    key: 'lastCheckoutEarly',
    label: 'field.lastCheckoutEarly',
    hideToggle: true,
  },
  {
    key: 'firstCheckinDateTime',
    label: 'field.firstCheckinTime',
    type: 'time',
    tdClass: 'text-success',
    hideToggle: true,
  },
  {
    key: 'firstCheckinImage',
    label: 'field.firstCheckinImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'firstCheckinAddress',
    label: 'field.firstCheckinAddress',
    type: 'map',
    latitudeKey: 'firstCheckinLatitude',
    longitudeKey: 'firstCheckinLongitude',
  },
  {
    key: 'firstCheckoutDateTime',
    label: 'field.firstCheckoutTime',
    type: 'time',
    tdClass: 'text-danger',
    hideToggle: true,
  },
  {
    key: 'firstCheckoutImage',
    label: 'field.firstCheckoutImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'firstCheckoutAddress',
    label: 'field.firstCheckoutAddress',
    type: 'map',
    latitudeKey: 'firstCheckoutLatitude',
    longitudeKey: 'firstCheckoutLongitude',
  },
  {
    key: 'firstRemark',
    label: 'field.firstRemark',
  },
  {
    key: 'lastCheckinDateTime',
    label: 'field.lastCheckinTime',
    type: 'time',
    tdClass: 'text-success',
    hideToggle: true,
  },
  {
    key: 'lastCheckinImage',
    label: 'field.lastCheckinImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'lastCheckinAddress',
    label: 'field.lastCheckinAddress',
    type: 'map',
    latitudeKey: 'lastCheckinLatitude',
    longitudeKey: 'lastCheckinLongitude',
  },
  {
    key: 'lastCheckoutDateTime',
    label: 'field.lastCheckoutTime',
    type: 'time',
    tdClass: 'text-danger',
    hideToggle: true,
  },
  {
    key: 'lastCheckoutImage',
    label: 'field.lastCheckoutImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'lastCheckoutAddress',
    label: 'field.lastCheckoutAddress',
    type: 'map',
    latitudeKey: 'lastCheckoutLatitude',
    longitudeKey: 'lastCheckoutLongitude',
  },
  {
    key: 'lastRemark',
    label: 'field.lastRemark',
  },
];
